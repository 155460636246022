<template>
  <div class="pb-10 pa-5">
    <div class="justify-center d-flex">
      <p class="text-center text-body-1 font-weight-regular blink info-text">
        {{ items[selectedIndex] }}
      </p>
    </div>
    <div
      class="justify-center d-flex"
    >
      <v-progress-circular
        :size="50"
        :width="2"
        color="primary"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InProgress',

  props: {
    slug: { type: String, default: '' }
  },

  data () {
    return {
      items: [
        'Vielen Dank! Ihre zusätzlichen Materialien werden nun personalisiert. Dies kann einige Minuten dauern.',
        'Wir generieren Ihre personalisierte Landingpage und zugehörige Dokumente.',
        'Hier erscheinen die fertigen Materialien, sobald die Personalisierung abgeschlossen ist.'],
      selectedIndex: 0
    }
  },

  mounted () {
    this.selectedIndex = 0
    setInterval(() => {
      this.selectedIndex = this.selectedIndex >= (this.items.length - 1) ? 0 : this.selectedIndex + 1
    }, 6000)
  }

}
</script>

<style scoped>
.info-text {
  max-width: 300px;
  height: 100px;
}

.animation-image {
  height: 50px;
  width: 200px;
}

.blink {
  animation: blink-animation 3s  infinite;
  -webkit-animation: blink-animation 3s infinite;
}

@keyframes blink-animation {
    0% { opacity: 0; }
    30% { opacity: 1; }
    50% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: 0; }
}

@-webkit-keyframes blink-animation {
    0% { opacity: 0; }
    30% { opacity: 1; }
    50% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: 0; }
}
</style>
